import styles from "./TestPage.module.css";
import { Header } from "../../components/header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import {
  clearLogsState,
  clearRequestParamsListState,
  clearRequestsListState,
  clearResultOfRequestState,
  clearServiceListState,
  fetchLogsStart,
  fetchRequestParamsListStart,
  fetchRequestsListStart,
  fetchResultOfRequestStart,
  fetchServiceListStart,
} from "../../api/test";
import { Navigate } from "react-router-dom";
import { AppPages, LinkSupportButtons } from "../../types";
import {
  checkIsAuth,
  getDefaultUrl,
  transformBody,
  transformHeaders,
} from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { FetchStatus } from "../../api/test/types";
import { ServiceParams } from "../../components/test/service-params/ServiceParams";
import { ServiceItem } from "../../components/test/service-item/ServiceItem";
import { SettingsBlock } from "../../components/test/settings-block/SettingsBlock";
import { ArrowBack } from "../../components/test/arrow-back/ArrowBack";
import { FormButton } from "../../components/buttons/form-button/FormButton";
import { Spinner } from "../../components/spinner/Spinner";
import { ExpectedResult } from "../../components/test/expected-result/ExpectedResult";
import { Category } from "../../components/test/category/Category";
import { getInstance } from "../../api/instance/getInstance";
import { PageLinks } from "../../components/test/page-links/settings-block/PageLinks";
import { testPages } from "./settings";
import { SaveVars } from "../../components/test/save-vars/SaveVars";

const LINKS_LIST = Object.values(LinkSupportButtons);
const SAVE_VARS = ["token", "trace"];
type TestPageProps = {};

export const TestPage: React.FC<TestPageProps> = () => {
  // let initialREQ_ID = crypto.randomUUID()
  const [selectedLink, setSelectedLink] = useState(
    LinkSupportButtons.WORKERS_TESTING
  );
  const [isAuth, setIsAuth] = useState(true);
  const [modal, setModal] = useState<boolean>(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [page, setPage] = useState("serviceList");
  const [currentResult, setCurrentResult] = useState<{
    [key: string]: boolean;
  }>({ 1: false });
  const requestParams = useAppSelector((state) => state.test.requestsParams);
  const resultOfRequest = useAppSelector((state) => state.test.resultOfRequest);
  const fetchResultOfRequest = useAppSelector(
    (state) => state.test.fetchResultOfRequest
  );
  const logs = useAppSelector((state) => state.test.logs);
  const fetchLogsStatus = useAppSelector((state) => state.test.fetchLogs);
  const allServices = useAppSelector((state) => state.test.serviceList);
  const serviceRequests = useAppSelector((state) => state.test.requests);
  const [listCategories, setListCategories] = useState<Array<string>>([]);
  const [selectedListCategory, setSelectedListCategory] = useState<string>();
  const [categories, setCategories] = useState<Array<string>>([]);
  const [REQ_ID, setREQ_ID] = useState("");
  const [displayTrace, setDisplayTrace] = useState(false);

  const [inputValues, setInputValues] = useState(
    {} as { [key: string]: string }
  );

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const dispatch = useAppDispatch();
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";
  const [instance, setInstance] = useState("default");
  const [pageInstance, setPageInstance] = useState("dev");
  const [varsIsOpen, setVarsIsOpen] = useState(false);

  useEffect(() => {
    dispatch(clearServiceListState());
    dispatch(clearLogsState());
    dispatch(fetchServiceListStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
    getInstance().then((response) => setPageInstance(response));
  }, [dispatch, token]);

  useEffect(() => {
    if (serviceRequests?.result?.vars?.INSTANCE) {
      setInstance(serviceRequests?.result?.vars?.INSTANCE);
    }
  }, [dispatch, serviceRequests?.result?.vars?.INSTANCE]);

  let cts: string[] = [];
  for (let item of serviceRequests?.result?.params) {
    if (item.category) {
      cts.push(item.category);
    }
  }

  useEffect(() => {
    let uniqueArray: any[] = [];
    if (cts && cts.length) {
      uniqueArray = Array.from(new Set(cts));
    }
    if (uniqueArray.length > 1) {
      let sortedArray = uniqueArray.sort();
      sortedArray.push("standart");
      setCategories(sortedArray);
    } else {
      setCategories([]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, cts.length]);

  let list_cts: string[] = [];
  for (let item of allServices?.result) {
    if (item.category) {
      list_cts.push(item.category);
    }
  }

  useEffect(() => {
    let uniqueArray: any[] = [];
    if (list_cts && list_cts.length) {
      uniqueArray = Array.from(new Set(list_cts));
    }
    if (uniqueArray.length > 1) {
      let sortedArray = uniqueArray.sort();
      // sortedArray.push("standart");
      setListCategories(sortedArray);
    } else {
      setListCategories([]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, list_cts.length]);

  function returnTestPattern(test: { [x: string]: any }) {
    let testPattern = test?.[instance] ? test?.[instance] : test?.["default"];
    return testPattern;
  }

  function handleSvgVars() {
    setVarsIsOpen(!varsIsOpen);
  }

  return isAuth ? (
    <>
      <Header selectedMenu="IT Support"></Header>
      <TabsMenu
        className={styles.tabs}
        linkEnum={LinkSupportButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <PageLinks testPages={testPages} instance={pageInstance} />
      <section className={styles.main}>
        <div className="container">
          <PageLinks testPages={testPages} instance={pageInstance}/>
          {page === "serviceList" ? (
            <div className={styles.flex}>
              <div className={styles.saveFlexBlock}>
                <svg
                  className={styles.saveSvg}
                  role="button"
                  onClick={handleSvgVars}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
                </svg>

                <SaveVars
                  className={
                    varsIsOpen
                      ? styles.saveVarsBlockOpen
                      : styles.saveVarsBlockHidden
                  }
                  varsList={SAVE_VARS}
                />
              </div>
              <div className={styles.block}>
                {!listCategories.length
                  ? (() => {
                      return allServices.result.map((service) => (
                        <ServiceItem
                          key={service.name}
                          service={service}
                          onClick={() => {
                            dispatch(clearRequestsListState());
                            dispatch(
                              fetchRequestsListStart({
                                serviceName: service.name,
                                accessToken: token,
                              })
                            );
                            setServiceName(service.name);
                            setPage("serviceRequests");
                          }}
                        />
                      ));
                    })()
                  : (() => {
                      if (!selectedListCategory) {
                        let categoriesArray = [];
                        for (let category of listCategories) {
                          categoriesArray.push(
                            <Category
                              categoryName={category}
                              key={category}
                              onClick={() => {
                                setSelectedListCategory(category);
                              }}
                            />
                          );
                        }
                        return <>{categoriesArray}</>;
                      } else {
                        let services = [];
                        for (let service of allServices.result.filter(
                          (item) => selectedListCategory === item.category
                        )) {
                          services.push(
                            <ServiceItem
                              key={service.name}
                              service={service}
                              onClick={() => {
                                dispatch(clearRequestsListState());
                                dispatch(
                                  fetchRequestsListStart({
                                    serviceName: service.name,
                                    accessToken: token,
                                  })
                                );
                                setServiceName(service.name);
                                setPage("serviceRequests");
                              }}
                            />
                          );
                        }
                        return (
                          <>
                            <ArrowBack
                              onClick={() => {
                                dispatch(clearServiceListState());
                                setSelectedListCategory(undefined);
                                setPage("serviceList");
                                dispatch(
                                  fetchServiceListStart({
                                    accessToken: token,
                                  })
                                );
                              }}
                            />
                            {services}
                          </>
                        );
                      }
                    })()}
              </div>
            </div>
          ) : null}
          {page === "serviceRequests" ? (
            <div className={styles.block}>
              {/* <ArrowBack
                onClick={() => {
                  dispatch(clearRequestsListState());
                  setSelectedCategory(undefined);
                  setPage("serviceList");
                  dispatch(fetchServiceListStart({ accessToken: token }));
                }}
              /> */}

              {!categories.length
                ? (() => {
                    let paramsArray = [];
                    for (let request of serviceRequests.result.params) {
                      paramsArray.push(
                        <ServiceParams
                          request={request}
                          key={request.name + "_" + request.id}
                          onClick={() => {
                            dispatch(clearRequestsListState());
                            dispatch(
                              fetchRequestParamsListStart({
                                serviceName: request.name,
                                requestId: request.id,
                                accessToken: token,
                              })
                            );
                            setServiceName(request.name);
                            setServiceId(request.id);
                            setPage("requestParams");
                            setREQ_ID(crypto.randomUUID());
                          }}
                        />
                      );
                    }
                    return (
                      <>
                        <ArrowBack
                          onClick={() => {
                            dispatch(clearRequestsListState());
                            setSelectedCategory(undefined);
                            setPage("serviceList");
                            dispatch(
                              fetchServiceListStart({ accessToken: token })
                            );
                          }}
                        />
                        <SettingsBlock
                          serviceRequests={serviceRequests}
                          paramsArray={paramsArray}
                        />
                      </>
                    );
                  })()
                : (() => {
                    if (!selectedCategory) {
                      let categoriesArray = [];
                      for (let category of categories) {
                        categoriesArray.push(
                          <Category
                            categoryName={category}
                            key={category}
                            onClick={() => {
                              setSelectedCategory(category);
                            }}
                          />
                        );
                      }
                      return (
                        <>
                          <ArrowBack
                            onClick={() => {
                              // dispatch(clearRequestsListState());
                              setPage("serviceList");
                              setSelectedCategory(undefined);
                              dispatch(
                                fetchServiceListStart({ accessToken: token })
                              );
                            }}
                          />
                          <SettingsBlock serviceRequests={serviceRequests} />
                          {categoriesArray}
                        </>
                      );
                    } else {
                      let paramsArray = [];
                      for (let request of serviceRequests.result.params.filter(
                        (item) =>
                          (selectedCategory === "standart" && !item.category) ||
                          selectedCategory === item.category
                      )) {
                        paramsArray.push(
                          <ServiceParams
                            request={request}
                            key={request.name + "_" + request.id}
                            onClick={() => {
                              // dispatch(clearRequestsListState());
                              dispatch(
                                fetchRequestParamsListStart({
                                  serviceName: request.name,
                                  requestId: request.id,
                                  accessToken: token,
                                })
                              );
                              setServiceName(request.name);
                              setServiceId(request.id);
                              setPage("requestParams");
                              setREQ_ID(crypto.randomUUID());
                            }}
                          />
                        );
                      }
                      return (
                        <>
                          <ArrowBack
                            onClick={() => {
                              // dispatch(clearRequestsListState());
                              setSelectedCategory(undefined);
                              setPage("serviceRequests");
                              dispatch(
                                fetchServiceListStart({ accessToken: token })
                              );
                            }}
                          />
                          <SettingsBlock
                            serviceRequests={serviceRequests}
                            paramsArray={paramsArray}
                          />
                        </>
                      );
                    }
                  })()}
            </div>
          ) : null}

          {page === "requestParams" ? (
            <div>
              <ArrowBack
                onClick={() => {
                  dispatch(clearRequestParamsListState());
                  dispatch(clearResultOfRequestState());
                  setPage("serviceRequests");
                  setCurrentResult({});
                  setModal(false);
                  setInputValues({});
                  setDisplayTrace(false);
                  dispatch(clearLogsState());
                  dispatch(
                    fetchRequestsListStart({
                      serviceName: serviceName,
                      accessToken: token,
                    })
                  );
                }}
              />
              <div className={styles.requestParams_flex}>
                <div className={styles.mainRequestsBlock}>
                  {requestParams?.result.test.map((test) =>
                    returnTestPattern(test).map((item: any, index: number) => {
                      return (
                        <>
                          <div
                            className={
                              modal
                                ? `${styles.requestSubBlock} ${styles.requestSubBlockHidden}`
                                : styles.requestSubBlock
                            }
                          >
                            <div>
                              <div className={styles.descrBlock}>
                                <p className={styles.descr}>{`${index + 1}) ${
                                  item.descr
                                }`}</p>
                                <svg
                                  className={
                                    modal ? styles.svgClicked : styles.svg
                                  }
                                  role="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (modal) {
                                      setModal(false);
                                    } else {
                                      setModal(true);
                                    }
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                                </svg>
                              </div>
                              <label className={styles.label}>Url</label>
                              <input
                                name={"url" + (index + 1)}
                                className={styles.input}
                                onChange={(e) =>
                                  setInputValues({
                                    ...inputValues,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                type={"text" + (index + 1)}
                                value={
                                  inputValues["url" + (index + 1)] ||
                                  inputValues["url" + (index + 1)] === ""
                                    ? inputValues["url" + (index + 1)]
                                    : getDefaultUrl(
                                        requestParams?.result.url,
                                        item.defaults.url
                                      )
                                }
                              />
                              <p className={styles.p}>
                                Method:{" "}
                                {requestParams?.result.method.toUpperCase()}
                              </p>
                              <label className={styles.label}>Headers</label>
                              <textarea
                                name={"headers" + (index + 1)}
                                className={styles.textarea}
                                onChange={(e) =>
                                  setInputValues({
                                    ...inputValues,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={
                                  inputValues["headers" + (index + 1)] ||
                                  inputValues["headers" + (index + 1)] === ""
                                    ? inputValues["headers" + (index + 1)]
                                    : test.headers
                                    ? JSON.stringify(
                                        Object.assign(
                                          {},
                                          transformHeaders(
                                            test.headers,
                                            item.defaults.headers
                                          ),
                                          {
                                            f2x_request_id: REQ_ID,
                                            f2x_trace: sessionStorage.getItem(
                                              "trace"
                                            )
                                              ? JSON.parse(
                                                  sessionStorage.getItem(
                                                    "trace"
                                                  )!
                                                )
                                              : "0",
                                          }
                                        ),
                                        null,
                                        2
                                      )
                                    : JSON.stringify(
                                        {
                                          f2x_request_id: REQ_ID,
                                          f2x_trace: sessionStorage.getItem(
                                            "trace"
                                          )
                                            ? JSON.parse(
                                                sessionStorage.getItem("trace")!
                                              )
                                            : "0",
                                        },
                                        null,
                                        2
                                      )
                                }
                              ></textarea>

                              {test?.body ? (
                                <>
                                  <label className={styles.label}>Body</label>
                                  <textarea
                                    name={"body" + (index + 1)}
                                    className={styles.textarea}
                                    onChange={(e) =>
                                      setInputValues({
                                        ...inputValues,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    value={
                                      inputValues["body" + (index + 1)] ||
                                      inputValues["body" + (index + 1)] === ""
                                        ? inputValues["body" + (index + 1)]
                                        : JSON.stringify(
                                            transformBody(
                                              test?.body,
                                              item.defaults.body
                                            ),
                                            null,
                                            2
                                          )
                                    }
                                  ></textarea>
                                </>
                              ) : null}

                              <FormButton
                                role="button"
                                children="Send Request"
                                className={styles.button}
                                onClick={() => {
                                  setDisplayTrace(false);
                                  setCurrentResult({
                                    ...currentResult,
                                    [index + 1]: true,
                                  });
                                  dispatch(
                                    fetchResultOfRequestStart({
                                      serviceName: serviceName,
                                      requestId: serviceId,
                                      accessToken: token,
                                      url: inputValues["url" + (index + 1)]
                                        ? inputValues["url" + (index + 1)]
                                        : getDefaultUrl(
                                            requestParams?.result.url,
                                            item.defaults.url
                                          ),
                                      reqBody: inputValues["body" + (index + 1)]
                                        ? JSON.parse(
                                            inputValues["body" + (index + 1)]
                                          )
                                        : transformBody(
                                            test?.body,
                                            item.defaults.body
                                          ),
                                      method: requestParams?.result.method,
                                      reqHeaders: inputValues[
                                        "headers" + (index + 1)
                                      ]
                                        ? JSON.parse(
                                            inputValues["headers" + (index + 1)]
                                          )
                                        : Object.assign(
                                            {},
                                            transformHeaders(
                                              test.headers,
                                              item.defaults.headers
                                            ),
                                            {
                                              f2x_request_id: REQ_ID,
                                              f2x_trace: sessionStorage.getItem(
                                                "trace"
                                              )
                                                ? JSON.parse(
                                                    sessionStorage.getItem(
                                                      "trace"
                                                    )!
                                                  )
                                                : "0",
                                            }
                                          ),
                                      index: (index + 1).toString(),
                                    })
                                  );
                                }}
                              />
                            </div>
                            {currentResult[index + 1] ? (
                              <div>
                                <div className={styles.resultBlock}>
                                  <div className={styles.flexResult}>
                                    <p className={styles.descr}>Result</p>
                                    <FormButton
                                      role="button"
                                      children="Get Trace"
                                      className={styles.buttonTrace}
                                      onClick={() => {
                                        setTimeout(() => {
                                          dispatch(
                                            fetchLogsStart({
                                              accessToken: token,
                                              id: REQ_ID,
                                            })
                                          );
                                          setDisplayTrace(true);
                                        }, 3500);
                                      }}
                                    />
                                  </div>
                                  {fetchResultOfRequest[index + 1] ===
                                  FetchStatus.PENDING ? (
                                    <Spinner
                                      width={50}
                                      height={50}
                                      marginTop="10px"
                                    />
                                  ) : (
                                    <textarea
                                      readOnly
                                      className={styles.textareaResult}
                                      value={JSON.stringify(
                                        resultOfRequest[index + 1],
                                        null,
                                        2
                                      )}
                                    ></textarea>
                                  )}
                                  <ExpectedResult
                                    item={item}
                                    resultOfRequest={resultOfRequest[index + 1]}
                                    fetchResultOfRequest={
                                      fetchResultOfRequest[index + 1]
                                    }
                                  />

                                  <div
                                    className={
                                      displayTrace
                                        ? styles.traceResult
                                        : styles.traceResultHidden
                                    }
                                  >
                                    <p
                                      className={styles.cross}
                                      role="button"
                                      onClick={() => {
                                        setDisplayTrace(false);
                                      }}
                                    >
                                      x
                                    </p>
                                    {fetchLogsStatus === FetchStatus.PENDING ? (
                                      <Spinner
                                        width={50}
                                        height={50}
                                        marginTop="10px"
                                      />
                                    ) : (
                                      <textarea
                                        readOnly
                                        className={styles.textareaTraceResult}
                                        value={logs!}
                                          // .replaceAll("\\n", "")
                                          // .replaceAll("\\", "")
                                          // .replaceAll("  ", "")
                                          // .replaceAll(
                                          //   '{"serviceName"',
                                          //   '\n\n{"serviceName"'
                                          // )}
                                      ></textarea>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
                {modal ? (
                  <div className={styles.jsonBlock}>
                    <textarea
                      readOnly
                      className={styles.textareaJson}
                      value={JSON.stringify(requestParams?.result, null, 2)}
                    ></textarea>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
