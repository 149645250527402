
export async function logoutApi(accessToken: string) {
  const server = "/api/authorization/logout";
  let headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
    "content-type": "application/json",
  });
  try {
    const response = await fetch(server, {
      method: "GET",
      headers: headers,
    });
    localStorage.removeItem("accessToken");
    return response;
  } catch (error) {
    console.log(error);
  }
}
